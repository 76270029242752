import { render, staticRenderFns } from "./EresEmpresa.vue?vue&type=template&id=40c5922c&scoped=true&"
import script from "./EresEmpresa.vue?vue&type=script&lang=js&"
export * from "./EresEmpresa.vue?vue&type=script&lang=js&"
import style0 from "./EresEmpresa.vue?vue&type=style&index=0&id=40c5922c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c5922c",
  null
  
)

export default component.exports