<template>
  <div id="empresas">

      <!-- <div class="heading">
          <h1 class="main-title" v-html="$t('eresempresa.t1')"></h1>
      </div> -->
      <CarouselEmpresa />

      <div class="contrata">
          <h3 v-html="$t('eresempresa.t2')"></h3>
          <p v-html="$t('eresempresa.p1')"></p>
      </div>
      
    <Ofrecerte />

    <div class="my-5">
        <p style="margin-bottom: 3rem;" v-html="$t('eresempresa.p4')"></p>

        <!-- <b-button @click="scrollToSection" class="px-5 py-2 rounded-pill" variant="black">{{$t('eresempresa.button2')}}</b-button> -->
        <b-button href="https://cloud.edu.mindhubweb.com/empresas" class="px-5 py-2 rounded-pill" variant="black">{{
          $t("somosmh.button")
        }}</b-button>
    </div>

    <div class="mt-5">
        <!-- <h2 class="font-weight-bolder">{{$tc('eresempresa.t4')}}</h2> -->
        <CarouselEmpresas />
    </div>
    
    <Capacitar />

    <div class="my-5">
      <b-button href="https://image.edu.mindhubweb.com/lib/fe3211737164047f761d73/m/1/5c419259-03cf-4938-a58a-f6e8944b246f.pdf" class="px-5 py-2 rounded-pill" variant="black">{{
        $t("eresempresa.button3")
      }}</b-button>
    </div>
    
    <FaqEmpresa />

    <Multiempresas />
    
    <!-- <div>
        <b-container fluid>
            <b-row style="margin-bottom: 1rem;">
                <b-col cols="12" md="4" class="p-2 bgk-green d-flex align-items-center justify-content-center">
                    <p class="font-weight-bolder p-0 m-0" style="font-size: 1.75rem" v-html="$tc('eresempresa.pyme.title')"></p>
                </b-col>
                <b-col cols="12" md="8" class="bgk-black text-left px-5 py-4">
                    <p class="text-white p-0 m-0" style="font-size: .9rem" v-html="$tc('eresempresa.pyme.desc')"></p>
                    <br>
                    <p class="text-white p-0 m-0" style="font-size: .9rem" v-html="$tc('eresempresa.pyme.desc2')"></p>
                </b-col>
            </b-row>
        </b-container>
    </div> -->
    <!-- <b-row>
        <b-col cols="4" class="p-5 d-none d-md-block">
            <img class="img-fluid w-75" src="..\assets\ilustraciones\blue_squares.png" alt="">
        </b-col>
    </b-row> -->
    
    <b-modal size="xl" id="download-b2b" hide-footer>
            <iframe src="https://info.mindhubweb.com/syllabus-b2b" frameborder="0" class="w-100" style="height: 500px"></iframe>
    </b-modal>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import { i18n } from '@/plugins/i18n'
import CarouselEmpresas from '../components/empresas/carouselEmpresas'
import CarouselEmpresa from '../components/empresas/carouselEmpresa'
import Ofrecerte from '../components/empresas/ofrecerte'
import Capacitar from '../components/empresas/capacitar'
import Multiempresas from '../components/empresas/multiempresas'
import FaqEmpresa from '../components/empresas/faq'
export default {
    name: 'EresEmpresa',
    components: {
        CarouselEmpresas,
        CarouselEmpresa,
        Ofrecerte,
        Capacitar,
        Multiempresas,
        FaqEmpresa
    },
    data(){
        return{
            mq4: window.matchMedia('(min-width: 1350px)'),
            
        }
    },
    methods: {
        
        ...mapMutations(['styleNav']),
        scrollToSection() {
      // Obtener el elemento objetivo
      const target = document.getElementById('faq');
      // Desplazarse al elemento objetivo
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
        // Ajustar el desplazamiento después de un pequeño retraso
        setTimeout(() => {
          window.scrollBy(0, 100); // Ajusta este valor según sea necesario
        }, 500); // Ajustar el tiempo de espera según sea necesario
      }
    }
        
    },
    computed:{
        lang(){
            return i18n.locale
        },
        styleObj(){
            return{
                backgroundImage: 'url('+require(this.lang == 'es' ? '../assets/empresas/bannerchicotittle.jpg' : '../assets/empresas/bannerchicotittleen.jpg')+')',
                height: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'bottom'
            }
        }

    },
    beforeMount(){
        this.styleNav({type: 'dark', variant: 'blue', logo: 'logo_blanco.webp'})
        window.addEventListener('resize', () => this.mq4 = window.matchMedia('(min-width: 1350px)'))
    }

}
</script>

<style scoped>


.heading{
    background-image: url('../assets/empresas/banner2.jpg');
    padding: 10% 5%;
    padding-top: 30%;
    color: #baff43;
    background-size: cover;
    text-align: left;
    background-position: top;
}
.heading h1{
    
    font-weight: bolder;
    font-size: 1.5rem;
}
@media screen and (min-width: 320px){
    .heading{
    padding-top: 0%;
  }
    .heading h1{
        
        font-size: 2rem;
    }
}
@media screen and (min-width: 425px) {
   .heading{
    padding-top: 35%;
  }
  .heading h1{
        
        font-size: 2rem;
    }
  }
  @media screen and (min-width: 768px) {
  .heading{
    padding-top: 20%;
  }
  .heading h1{
        
        font-size: 2.5rem;
    }
  }

@media screen and (min-width: 1000px){
   .heading{
    padding-top: 15%;
  }
    .heading h1{
        
        font-size: 3rem;
    }
}
@media screen and (min-width: 1300px){
    .heading{
    padding-top: 15%;
  }
    .heading h1{
        
        font-size: 3em;
    }
}

.contrata{
    padding: 100px 5%;
}
.contrata p{
        padding: 0px 5%;
}

.contrata h3{
    /* color: #ff00ff; */
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: bold;
}

@media screen and (min-width: 1024px){
    .contrata h3{
        font-size: 1.75rem;
    }

    .contrata p{
        padding: 0px 15%;
    }
}

#empresas p{
    font-size: 1.25rem;
}

.triangle{
    position: absolute;
    top:0;
    left: 3em;
}


</style>