<template>

  <div id="carousel">
     
     
    <b-carousel
      v-model="slide"
      id="carousel-1"
      padding= "10"
      :interval="50000000"
      fade
      controls
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
    >
    <b-carousel-slide
         v-bind:caption-html= " $t('carouselEmpresa.c1') "         
        :img-src="require('../../assets/banner/foto1.webp')"
      ><div class="carousel-caption-button">
          <b-button @click="scrollToSection" class="custom-button" variant="pink">
            {{$t('eresempresa.button5')}}
          </b-button>
        </div>
      </b-carousel-slide>

      <b-carousel-slide
        v-bind:caption-html= " $t('carouselEmpresa.c2') " 
        :img-src="require('../../assets/banner/foto2.webp')"
        class="azul"
      ><div class="carousel-caption-button">
          <b-button @click="scrollToSection" class="custom-button" variant="pink">
            {{$t('eresempresa.button5')}}
          </b-button>
        </div>
      </b-carousel-slide>
      
      <b-carousel-slide
        v-bind:caption-html= " $t('carouselEmpresa.c3') "         
        :img-src="require('../../assets/banner/foto3.webp')"
      ><div class="carousel-caption-button">
          <b-button @click="scrollToSection" class="custom-button" variant="pink">
            {{$t('eresempresa.button5')}}
          </b-button>
        </div>
      </b-carousel-slide>
      
      <b-carousel-slide
        v-bind:caption-html= " `<span style='color: white;'>${$t('carouselEmpresa.c4')}</span>` " 
        :img-src="require('../../assets/banner/foto4.webp')"

      ><div class="carousel-caption-button">
          <b-button @click="scrollToSection" class="custom-button" variant="pink">
            {{$t('eresempresa.button5')}}
          </b-button>
        </div>
      </b-carousel-slide>

      <!-- <b-carousel-slide
        v-bind:caption-html= " $t('carouselEmpresa.c5') " 
        :img-src="require('../../assets/banner/foto5.webp')"
      @click.native="goToPage"
        style="cursor: pointer;"
      ></b-carousel-slide> -->
      
    </b-carousel>
  </div>
  
</template>
<style>

.custom-button {
  padding: 5px 10px;
}

@media screen and (max-width: 425px) {
  #carousel{
    margin-top: 0px;
  }
}

#carousel .carousel-caption{
  bottom: 50%!important;
  transform: translateY(-75%);
  text-align: left!important;
  /* width: 70%; */
}
#carousel .carousel-caption h3{
    text-align: left;
    color: #BAFF43;
    font-weight: 900;
    font-size: 1em;
}

/*#carousel .carousel-item:nth-child(2) h3{
  color: #0000FF;
}*/

@media screen and (min-width: 375px) {
  #carousel .carousel-caption h3{
    font-size: 1em;
  }
  #carousel .carousel-caption{
    bottom: 20%!important;
    text-align: left!important;
    transform: translateY(-45%);
  }

  .custom-button {
    padding: 5px 10px;
    font-size: 0.8em;
  }
}

@media screen and (min-width: 425px) {
  #carousel .carousel-caption h3{
    font-size: 1.5em;
  }
  #carousel .carousel-caption{
    bottom: 20%!important;
    text-align: left!important;
    transform: translateY(30%);
  }
}

@media screen and (min-width: 768px) {
  #carousel .carousel-caption h3{
    font-size: 2em;
  }
  #carousel .carousel-caption{
    bottom: 30%!important;
    text-align: left!important;
    transform: translateY(-15%);
  }
}

@media screen and (min-width: 1024px) {
  #carousel .carousel-caption h3{
    font-size: 2.2em;
  }
  #carousel .carousel-caption{
    bottom: 35%!important;
    text-align: left!important;
    transform: translateY(-37%);
  }
  .custom-button {
    padding: 5px 10px;
    font-size: 1em;
  }
}

@media screen and (min-width: 1300px) {
  #carousel .carousel-caption h3{
    font-size: 2.5em;
  }
  #carousel .carousel-caption{
    bottom: 35%!important;
    transform: translateY(-75%);
    
  }
  .custom-button {
    padding: 5px 10px;
    font-size: 1.2em;
    z-index: 3;
    position: relative;
    right: 280%;
  }
  #carousel .carousel-item:nth-child(2) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 4%;
  }
  #carousel .carousel-item:nth-child(1) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 4%;
  }

  #carousel .carousel-item:nth-child(3) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 4%;
  }
  #carousel .carousel-item:nth-child(4) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 4%;
  }
}

.carousel-caption-button {
  position: absolute;
  /* bottom: 20px; */
  right: 20px;
  color: #ff00ff
}

</style>
<script>
import {mapMutations} from 'vuex'

export default {
  name: 'CarouselEmpresa',
  data(){
    return{
      slide: 0
    }
  },
  methods:{
    ...mapMutations(['navVariant']),
    goToPage() {
      window.open('https://cloud.edu.mindhubweb.com/openhouse', '_blank');
    },
    scrollToSection() {
      // Obtener el elemento objetivo
      const target = document.getElementById('capacitar');
      // Desplazarse al elemento objetivo
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
        // Ajustar el desplazamiento después de un pequeño retraso
        setTimeout(() => {
          window.scrollBy(0, 500); // Ajusta este valor según sea necesario
        }, 500); // Ajustar el tiempo de espera según sea necesario
      }
    }
  },
  watch:{
    slide(){
      if(this.slide % 2 == 0){
        this.navVariant('blue')
      }else{
        this.navVariant('pink')
      }
    }
  }
}
</script>