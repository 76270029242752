<template>
  <div class="ofrecerte">
    <!-- <h2>{{ $t("eresempresa.t3") }}</h2>   -->
    <!-- <h2></h2> -->
    <b-container fluid>
      <b-row>
        <b-col class="p-0">
          <!-- <div class="ofrecerte-card header-card">
            <div class="ofrecerte-desc"> -->
              <!-- <h3 v-html="$t('eresempresa.headerCard.title')"></h3> -->
              <!-- <p class="d-block">{{ $t("eresempresa.headerCard.desc") }}</p> -->
              <b-button
                href="https://cloud.edu.mindhubweb.com/empresas"
                variant="black"
                class="mb-5 px-5 py-2 rounded-pill"
                >{{ $t("eresempresa.button1") }}</b-button
              >
            <!-- </div>
          </div> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col class="p-0" cols="12" lg="4">
          <div class="ofrecerte-card card1">
            <div class="ofrecerte-desc">
              <h3 v-html="$t('eresempresa.card1.title')"></h3>
              <div class="content-wrapper">
                <p v-html="$t('eresempresa.card1.desc')"></p>
                <b-button v-html="$t('eresempresa.card1.a')" class=" rounded-pill" variant="black" href="https://image.edu.mindhubweb.com/lib/fe3211737164047f761d73/m/1/5c419259-03cf-4938-a58a-f6e8944b246f.pdf" target="_blank"></b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="p-0" cols="12" lg="4">
          <div class="ofrecerte-card card2">
            <div class="ofrecerte-desc">
              <h3 v-html="$t('eresempresa.card2.title')"></h3>
              <div class="content-wrapper">
                <p v-html="$t('eresempresa.card2.desc')"></p>
                <b-button v-html="$t('eresempresa.card2.a')" class=" rounded-pill" variant="black" href="https://image.edu.mindhubweb.com/lib/fe3211737164047f761d73/m/1/5c419259-03cf-4938-a58a-f6e8944b246f.pdf" target="_blank"></b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="p-0" cols="12" lg="4">
          <div class="ofrecerte-card card3">
            <div class="ofrecerte-desc">
              <h3 v-html="$t('eresempresa.card3.title')"></h3>
              <div class="content-wrapper">
                <p v-html="$t('eresempresa.card3.desc')"></p>
                <b-button v-html="$t('eresempresa.card3.a')" class=" rounded-pill" variant="black" href="https://image.edu.mindhubweb.com/lib/fe3211737164047f761d73/m/1/5c419259-03cf-4938-a58a-f6e8944b246f.pdf" target="_blank"></b-button>
                
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Ofrecerte",
};
</script>

<style scoped>
.ofrecerte{
    /* background: #000; */
    color: #fff;
}

.ofrecerte h2{
    font-weight: bolder;
    padding: 35px 0;
}

.ofrecerte-card{
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 350px;
}

@media screen and (max-width: 768px) {
  .ofrecerte-card h3{
    font-size: 1.25rem;
  }

  .ofrecerte-card p{
    font-size: .75rem;
  }

  .ofrecerte-card a{
    font-size: .75rem;
  }
}

@media screen and (min-width: 992px){
    .ofrecerte-card{
        height: 450px;
    }
}

@media screen and (min-width: 1200px){
    .ofrecerte-card{
        height: 550px;
    }
}

.ofrecerte-card.header-card{
    background-image: url('../../assets/empresas/contrata_egresados.jpg');
    
}

.ofrecerte-card.card1{
    background-image: url('../../assets/empresas/ofrece2.jpg');
    
}

.ofrecerte-card.card2{
    background-image: url('../../assets/empresas/ofrece3.jpg');
}

.ofrecerte-card.card3{
    background-image: url('../../assets/empresas/ofrece1.jpg');
    
}

.ofrecerte-card img{
    position: relative;
    z-index: 1;
}

.ofrecerte-card .ofrecerte-desc{
    /* position: absolute; */
    z-index: 2; 
    padding: 10%;
}

.ofrecerte-card h3{
    font-weight: bolder;
}

.ofrecerte-card p{
    margin-top: 20px;
}

.ofrecerte-card:hover .ofrecerte-desc {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media screen  and (min-width: 1024px){
    .ofrecerte-card .content-wrapper{
        display: none;
    }

    .ofrecerte-card:hover .content-wrapper{
        display: block;
    }
}
</style>